<template>
  <b-modal ref="modal" scrollable hide-footer @close="cancel">
    <template #modal-title>{{ phoneNumber }}</template>
    <b-row v-for="action in actions" :key="action.value" class="my-2">
      <b-col class="text-center">
        <b-button pill block variant="primary" @click="validate(action)">
          <b-icon :icon="action.icon"></b-icon>
          &nbsp; {{ action.label }}
        </b-button>
        <a
          class="d-none"
          :ref="action.value"
          :href="`${action.value}:${phoneNumber}`"
          target="_blank"
        ></a>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "PhoneNumberActionDialog",
  data: () => ({
    phoneNumber: null,
    actions: [
      { value: "tel", icon: "telephone-outbound-fill", label: "Appeler" },
      { value: "sms", icon: "chat-right-text-fill", label: "Envoyer un SMS" },
      {
        value: "copy",
        icon: "clipboard-check",
        label: "Copier le numéro",
        callback: (phoneNumber) =>
          document.defaultView.navigator.clipboard.writeText(phoneNumber),
      },
    ],
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options) {
      this.phoneNumber = options?.phoneNumber ?? "Numéro inconnu";
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
    validate(action) {
      if (action.callback) {
        action.callback(this.phoneNumber);
      } else {
        this.$refs[action.value][0].click();
      }
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
