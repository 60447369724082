<template>
  <span v-if="sanitizedValue" :class="classes">
    <b-icon-telephone v-if="displayIcon"></b-icon-telephone>
    <b-link href="#" @click.stop="openDialog">
      {{ sanitizedValue }}
    </b-link>
    <PhoneNumberActionDialog ref="dialog"></PhoneNumberActionDialog>
  </span>
</template>

<script>
import PhoneNumberActionDialog from "./dialogs/PhoneNumberActionDialog.vue";
export default {
  name: "PhoneNumberComponent",
  components: { PhoneNumberActionDialog },
  props: ["value", "icon", "classes"],
  computed: {
    sanitizedValue() {
      if (this.value?.length === 10) {
        let sanitized = "";
        for (let i = 0; i < this.value.length; i++) {
          if (i % 2 === 0 && i > 0) {
            sanitized += " ";
          }
          sanitized += this.value[i];
        }
        return sanitized;
      }
      return this.value;
    },
    displayIcon() {
      return this.icon === true || this.icon === "true";
    },
  },
  methods: {
    openDialog() {
      this.$refs.dialog.show({ phoneNumber: this.value });
    },
  },
};
</script>
