<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>
      <template>Modifier mon mot de passe</template>
    </template>
    <PasswordChangerComponent
      ref="component"
      :noButtons="true"
      @change="validate"
    ></PasswordChangerComponent>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button
              block
              pill
              variant="outline-danger"
              @click="$refs['component'].clear()"
            >
              R&eacute;initialiser
            </b-button>
          </b-col>
          <b-col>
            <b-button
              block
              pill
              variant="danger"
              @click="$refs['component'].validate()"
            >
              Valider
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import PasswordChangerComponent from "../backoffice/PasswordChangerComponent.vue";
export default {
  name: "PasswordChangerDialog",
  components: { PasswordChangerComponent },
  data: () => ({
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show() {
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    validate() {
      this.$refs["modal"].hide();
      this.resolvePromise(true);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
  },
};
</script>
